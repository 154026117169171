<template>
    <section id="dashboard-ecommerce">
      <div>

      
        <b-card
          no-body
          class="mb-0"
        >
        <div class="text-center mt-5" style="color: #b4b7bd !important;">
  
          <h3>
          Data Entry Statistics
        </h3>
        </div>
       
         
          <b-card-body>
            <b-row>
  
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ البداية </label>
                    <flat-pickr
                      v-model="start_date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="4"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="date"
                    :state="errors.length > 0 ? false : null"
                  >
                    <label>تاريخ النهاية </label>
                    <flat-pickr
                      v-model="end_date"
                      class="form-control"
                      placeholder="حدد تاريخ "
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                cols="4"
                md="4"
                
              >
  
                <label>Data Entry</label>
                <v-select
                  v-model="dataEntryId"
                  :options="DataEntryOption"
                  :reduce="option => option.value"
                  :clearable="true"
                />
  
              </b-col>
              
            </b-row>
<div v-if="dataEntryId">
  <b-row>
      <b-col
        md="12"
      ><b-card
        no-body
        class="mb-0"
      >
        <b-card-header> </b-card-header>
        <b-card-body>
     
          <!-- apex chart -->
          <vue-apex-charts
            height="400"
            :options="chartOptionsEmploye"
            :series="chartOptionsEmploye.series"
          />

          <!-- chart info -->
        </b-card-body>
      </b-card></b-col>
    </b-row>
  <br />
<br />
<b-row>
        <b-col
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="DatabaseIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                عدد الإحالات
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ services }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="DatabaseIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <label>
                 عدد المستفيدين
                </label>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ beneficiaries }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
</div>
     
         
            <div v-else class="text-center mt-5" style="color: #b4b7bd !important;">
  
                      <h5>
                      Select Data Entry To Display Statistics
                      </h5>
  </div>
           
         
          </b-card-body>
        </b-card>
        <hr>
  

      </div>
    </section>
  </template>
  
  <script>
  import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BPagination,
    BCardHeader,
    BCardBody,
    BFormInvalidFeedback,
    bCardTitle,
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import VueApexCharts from 'vue-apexcharts'
  import { ref, onUnmounted } from '@vue/composition-api'
  import flatPickr from 'vue-flatpickr-component'
  import store from '@/store'
  import 'flatpickr/dist/flatpickr.css'
  
  export default {
    
    components: {
      VueApexCharts,
      bCardTitle,
      BButton,
      
      BDropdownItemButton,
      BFormInvalidFeedback,
  
      BCard,
      BRow,
      BCol,
      BFormInput,
  
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      BCardHeader,
      BCardBody,
      flatPickr,
      vSelect,
    },
    data() {
      return {
        beneficiaries:'',
        services:'',
        DataEntryOption: [],
        label: [],

        chartOptionsEmploye: {
        series: [],
        chart: {
          type: 'pie',
          toolbar: {
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#17a2b8', '#FFA1A1'],

        labels: ['  الإحالات  ', ' المستفيدين '],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,

                total: {
                  show: false,
                  offsetY: 15,
                },
              },
            },
          },
        },
      },
        dataEntryId: '',
        end_date: '',
        start_date: '',
      }
    },
    watch: {

      start_date() {
        this.getdataEntryStats()
      },
      end_date() {
        this.getdataEntryStats()
      },
      dataEntryId() {
        this.getdataEntryStats()
      },

  
    },
    created() {
      let today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
  
      today = `${yyyy}-${mm}-${dd}`
      this.end_date = today
      this.start_date =  "2023-07-10"

      this.getdataEntryOption()

    },
    methods: {
  
      getdataEntryOption() {
        let x = null
        const url = '/api/v1/get-all-data-entry'
        this.$http.get(url).then(res => {
          window.dispatchEvent(new Event('resize'))
        //  console.log(res.data)
x = res.data
x.forEach((el) => {
            this.DataEntryOption.push({ value: el.id, label: `${el.first_name} ${el.last_name} `} )
      })
    })
      },

      getdataEntryStats() {
        const url = `/api/v1/stats/get-service-referrals-stats?start_date=${this.start_date}&end_date=${this.end_date}&user_id=${this.dataEntryId}`
        this.$http.get(url).then(res => {
          window.dispatchEvent(new Event('resize'))

         this.beneficiaries = res.data.data.beneficiaries
         this.services = res.data.data.referralCount
         const data = []

if (res.data.data) {
  data.push(parseInt(res.data.data.referralCount))
  data.push(parseInt(res.data.data.beneficiaries))
  this.chartOptionsEmploye.series = data
  window.dispatchEvent(new Event('resize'))
}

        })
      },


    },
  }
  
  </script>
  
  <style lang="scss">
  div {
    color: #7e7a9f;
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .chart-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .chart-item {
    flex: 1;
    margin: 0 10px;
  }
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  </style>
  